body {
    background-color: #ffffff;
    font-family: verdana, geneva, sans-serif;
    font-size: 12px;
    line-height: 20px;
}

p {
    margin-bottom: 1.25rem;
}

a {
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #494949;
    font-family: verdana, geneva, sans-serif;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: normal;
    padding: 0;
}

/* 2020 build asiakasviestintä*/

body.type-2020 {
    background-color: #d0d0d1;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 14px;
    /*16px*/
    line-height: 24px;

}

h1.mailer-style {
	font-size: 24px;
}

h2.mailer-style {
	font-size: 22px;
}

h3.mailer-style {
	font-size: 20px;
}

h4.mailer-style {
	font-size: 18px;
}

h5.mailer-style {
	font-size: 16px;
}

h6.mailer-style {
	font-size: 14px;
}



.mt-6 {
    margin-top: 3rem !important;
}

.mt-8 {
    margin-top: 4rem !important;
}

.mt-10 {
    margin-top: 5rem !important;
}

.bg-2020 {
    background-color: #d0d0d1;

}

.row-no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.border {
    border-left: 1px solid #d0d0d1;
    border-right: 1px solid #d0d0d1;
}

.blue-bg {
    background-color: #0019af;
}

.white-text,
.white-text h1,
.white-text h2,
.white-text h3,
.white-text h4,
.white-text h4,
.white-text h6,
    {
    color: #ffffff;
}

.btn {
    display: inline-block;
    font-weight: normal;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    -webkit-border-radius: 0.25rem;
    border-radius: 0.0rem;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

}

.btn-text-over-image {
    margin-top: 7rem;
}

@media screen and (max-width: 500px) {
    .btn-text-over-image {
        margin-top: 4rem;
    }
}

@media screen and (max-width: 375px) {
    .btn-text-over-image {
        margin-top: 2rem;
    }
}

/* Border blocks */

.grey-border {
    border-top: 15px solid #d0d0d1;
}

.white-border {
    border-top: 15px solid #ffffff;
}

/* Header block */

.img-logo {
    vertical-align: middle;
}

.contact-text-header {
    color: #0019af;
    font-size: 18px;
    text-align: center;
}

/* Contact block */


.img-contact {
    vertical-align: top;
}


.contact-text {
    color: #0019af;
    font-size: 12px;
}

/* Some, footer */

.some-icon {
    width: 18px;
}

.img-centered {
    margin: auto;
}

@media screen and (max-width: 500px) {
    .row.img-centered.some-wrapper {
        width: 60% !important;
    }
}


.footer-darkgrey {
    background-color: #3d444d;
    color: #ffffff;
}

.footer-logo {
    width: 90px;

}

.footer-darkgrey div p>.footer-link {
    text-decoration: underline !important;
    color: #ffffff !important;
    font-weight: bold !important;
}

/* Video */

.videoWrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56%;

}

.videoWrapper .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: #ffffff;
}

@media screen and (max-width: 380px) {
    .videoWrapper, .videoWrapper .video {
        /*display: none;*/
    }
}


ul {
	padding-inline-start:24px;
}



/*header {
    text-align: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    background-color: #ffffff;
    height: 50px;
}

.desktop-navigation {
    height: 100%;
}

ul.header {
    margin: 20px;
    margin-right: 20px;
    display: inline-block;
    padding: 0px;
}


.header-language-menu li,
li.header {
    padding: 13px 0;
    padding-top: 13px;
    padding-bottom: 13px;
}

li.header {

    list-style: none;
    text-transform: uppercase;

}

#nav li {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    list-style: none;
    text-transform: uppercase;
    color: #0019af;
    display: inline;
}

@media screen and (max-width: 600px) {
    #nav li {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 0;
        margin-bottom: 0;
        display: block;
    }
}*/