// Liana Wireframe Variables
// =============================================================================
// Background
// $canvas-bg: #fff;

// Border
// $border-color: $gray-light;

// Loader
// $loader-color: $gray-dark;
// $loader-bg: $body-bg;

// Sticky Footer
// $footer-height: 50px;

$box-shadow: 0 0 10px #464646;

.box-shadow.box-shadow {
	box-shadow: $box-shadow;
}
